import React from 'react';
import styled from "styled-components";

import CircleButton from './button';


const Container = styled.div`
  font-weight: 200;
  font-size: 72px;
  line-height: 96px;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (max-width: 568px) {
    font-weight: 200;
    font-size: 35px;
    line-height: 58px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`;


const BorderBottom = styled.div`
  width: 111px;
  margin-top: 28px;
  margin-bottom: 32px;
  border-bottom: solid 2px #4285F4;
`;


const ContentText = styled.div`
  font-size: 18px;
  line-height: 27px;
  max-width: 624px;
  color: #FFFFFF;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
  }
`;

const BoldTitle = styled.span`
  font-weight: 700
`

const BackgroundText = styled.div`
  font-size: 250px;
  position: absolute;
  text-align: right;
  color: #0D0C0C;
  right: auto;
  width: 1300px;
  word-break: break-word;
  margin-top: -160px;
  text-transform: uppercase;
  z-index: -9;
  font-weight: 300;
  line-height: 320px;
  text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.55);

  &::selection {
    background: transparent;
    color: #0D0C0C;
  }

  &::-moz-selection {
    background: transparent;
    color: #0D0C0C;
  }

  @media (max-width: 1320px) {
    width: 1200px;
  }
  @media (max-width: 1220px) {
    width: 1100px;
  }
  @media (max-width: 1120px) {
    width: 1000px;
  }
  @media (max-width: 1020px) {
    width: 900px;
  }
  @media (max-width: 968px) {
    display: none;
  }
`;


const Heading = ({title, description, button, buttonText, boldTitle, background = "Климат", toggleOpenModal}) => {

        return (
            <>
                <Container>
                    {title}
                    {boldTitle && <BoldTitle> {boldTitle}</BoldTitle>}
                    {background && <BackgroundText align="right">{background}</BackgroundText>}
                </Container>
                <BorderBottom/>
                <ContentText>{description}</ContentText>

                {button && (
                    <CircleButton
                        text={buttonText || "Хочу так же"}
                        toggleOpenModal={toggleOpenModal}
                    />
                )}
            </>
        );
    }
;

export default Heading;
