import React from "react";
import styled from "styled-components";



const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Card = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 16px;
  @media (max-width: 768px) {
    margin-top: 98px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 33px;
  margin-top: 62px;
  text-align: center;
  max-width: 275px;
  color: #FFFFFF;
  @media (max-width: 768px) {
    margin-top: 27px;
  }
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  margin-top: 33px;
  text-align: center;
  color: #FFFFFF;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 270px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 132px;
  }
`;

const CardsAchives = ({ data }) => {

  return (
    <Cards>
      {data.map((item, index) => {
        return (
          <Container key={index}>
            <Card src={item.image.localFile.publicURL} alt={item.title + "-image"} />
            <Title>{item.title}</Title>
            <Text>{item.description}</Text>
          </Container>
        );
      })}

    </Cards>
  );
};

export default CardsAchives;
